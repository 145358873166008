import { LanguageChange } from '../i18n/Components/LanguageChange';
import { useTranslate } from '../i18n/hooks/use-translate';
import { Link, useLocation } from 'react-router-dom';
import {
  getAppAccessHrefWithQueryParams,
  getAppSignUpHrefWithQueryParams,
  getHrefWithSameQueryParams,
} from '../utilities';

const Header = () => {
  const { i18n } = useTranslate();
  const { pathname } = useLocation();
  return (
    <header className="w-full items-center pt-6 pb-10 sm:pt-10 sm:pb-16">
      <div className="mx-auto flex flex-col sm:flex-row gap-8 sm:gap-0 items-center">
        <div className="grow flex items-center">
          <Link to={getHrefWithSameQueryParams('/')}>
            <img
              src="/images/logo-with-name.svg"
              className="h-8 cursor-pointer"
              alt="Lemeno"
            />
          </Link>
          {pathname === '/creators' && (
            <span className="ml-2 hidden md:block text-xs text-primary">
              {i18n('forCreators')}
            </span>
          )}

          {pathname === '/nerds' && (
            <span className="ml-2 hidden md:block text-xs text-primary">
              {i18n('forNerds')}
            </span>
          )}

          {pathname === '/optimizers' && (
            <span className="ml-2 hidden md:block text-xs text-primary">
              {i18n('forOptimizers')}
            </span>
          )}
        </div>
        <div className="flex items-center gap-6">
          <LanguageChange classWidth="w-4" classHeight="h-4" />
          <a
            href={getAppAccessHrefWithQueryParams()}
            className="text-white text-sm"
          >
            {i18n('logIn')}
          </a>
          <a
            href={getAppSignUpHrefWithQueryParams()}
            className="bg-primary hover:bg-primary/80 rounded py-3 px-4 text-neutral font-semibold text-sm"
          >
            {i18n('registerNow')}
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
